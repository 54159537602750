import React from 'react';
import { Grid, Heading, } from '@chakra-ui/react';


export default function PgReportOOT() {

  return (
    <>
      <Heading>PgReportOOT</Heading>
    </>
  );
}
