// import firebaseui from 'firebaseui';

import axios from 'axios';

const [__,__E,__W] = [console.log,console.error,console.warn];
import { create } from 'zustand';
import produce from 'immer';
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword,
  createUserWithEmailAndPassword, sendPasswordResetEmail, signOut,onAuthStateChanged, } from "firebase/auth";
import { getFirestore, query, getDocs, collection, where, addDoc, } from "firebase/firestore";
const googleProvider = new GoogleAuthProvider();
import { getAnalytics } from "firebase/analytics";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { serverOrigin } from '../data/constants.js';

// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebase = require('firebase');
// const firebaseui = require('firebaseui');
export const userSettingsDefaults={
  history: {},
  ethBal:['', '', ''],
}

export const lnpUserInfoDefaults={
  fetchedOnce:false,
  subscriptions:[{
    plan:'',
    status:'',
    nextPayment:'',
    total:'',
    apiKeys:[],
  }],
}

// Initialize Firebase
export const useAuth = create((set, get) => ({
  auth:null,
  app:null,
  db:null,
  analytics:null,
  isAuthenticated:null,
  user:null,
  userSettings: userSettingsDefaults,
  lnpUserInfo: lnpUserInfoDefaults,
  lnpUserSubscriptions: [],
  lnpUserSubscriptionsLoaded: false,
  lnpUserNewApiKey:'',
  config: {
    apiKey: "AIzaSyCSdyFbiyRDta6xBhff6KCJK5730egj5VQ",
    authDomain: "ccal-3abb2.firebaseapp.com",
    projectId: "ccal-3abb2",
    storageBucket: "ccal-3abb2.appspot.com",
    messagingSenderId: "436063158325",
    appId: "1:436063158325:web:36ece548397744aa97317c",
    measurementId: "G-4YBG6T0JMC"
  },
  uiConfig:{
    signInFlow: 'popup',
    signInSuccessUrl: '/dash',
    signInOptions: [
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false
      }
    ],
  },
  init: async() => {
    // const ui = new firebaseui.auth.AuthUI(firebase.auth());
    // set({firebaseUI: ui});
    // ui.start('#firebaseui-auth-container', fbAuth.uiConfig);
    const app = initializeApp(get().config)
    const auth = getAuth(app)
    const db = getFirestore(app)
    const analytics = getAnalytics(app)
    set({app});
    set({auth});
    set({db});
    set({analytics});
    onAuthStateChanged(get().auth, (currentuser) => {
      console.log("onAuthStateChanged: ", currentuser);
      set({user:currentuser});
      if(currentuser && currentuser.accessToken){
        set({isAuthenticated:true})
      }else{
        set({isAuthenticated:false})
      }
    })
  },
  initFirebaseUI: ()=>{
  },
  removeAttachment: async (suid, originalname)=>{
    const res = await axios.post(`${serverOrigin}/tools/rm/${suid}/${originalname}`)
    .catch(err=>console.error(err) && false);
    if(res && res.data.status === 'success' && res.data.results != null) {
      return true
    } else {
      return false
    }
  },
  signout: async () => {
    get()._s(s=>{s.lnpUserInfo = lnpUserInfoDefaults })
    get()._s(s=>{s.lnpUserSubscriptions = [] })
    await signOut(get().auth);
  },
  logInWithEmailAndPassword: async (email, password) => {
    try {
      const res = await signInWithEmailAndPassword(get().auth, email, password);
      const user = res.user;
      console.log('log in complete res: ', res)
      console.log('log in complete user: ', user)
    } catch (err) {
      console.error(err);
    }
  },
  registerWithEmailAndPassword: async (email, password) => {
    try {
      const res = await createUserWithEmailAndPassword(get().auth, email, password);
      const user = res.user;
      await addDoc(collection(get().db, "users"), {
        uid: user.uid,
        authProvider: "local",
        email,
      });
      console.log('sign up complete: ', user)

    } catch (err) {
      console.error(err);
    }
  },
  signInWithGoogle: async () => {
    try {
      const res = await signInWithPopup(get().auth, googleProvider);
      const user = res.user;
      const q = query(collection(get().db, "users"), where("uid", "==", user.uid));
      const docs = await getDocs(q);
      if (docs.docs.length === 0) {
        await addDoc(collection(get().db, "users"), {
          uid: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
        });
      }
    } catch (err) {
      console.error(err);
    }
  },
  sendPasswordReset: async (email) => {
    //send a password reset link to an email address
    try {
      await sendPasswordResetEmail(get().auth, email);
      console.log("Password reset link sent!");
      return `Email sent to ${email}`
    } catch (err) {
      console.error(err);
      return `Could not send to ${email}.  The email address is likely not yet registered.`
    }
  },
  fetchLnpUserInfo: async () => {
    __(`fetching lnpUserInfo`);
    await get().user.getIdToken(true)
    const user = get().user;
    const email = user.email
    const jwt = user.accessToken
    console.log('jwt', jwt)
    const uid = user.uid
    const response = await fetch(`/api/get-lnp-user-info`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, jwt, uid }),
    }).then(async (r)=> {
      const json = await r.json();
      return json.message
    });
    console.log(`get-lnp-user-info:`,response)

    if(response!=null && response.subscriptions!=null){
      get()._s(s=>{s.lnpUserInfo.subscriptions = response.subscriptions })
      get()._s(s=>{s.lnpUserInfo.apiKeys = response.apiKeys })
      get()._s(s=>{s.lnpUserInfo.fetchedOnce = true })

      // if subscriptions exist, retrieve data from stripe using client_secrets for each sub
      if(response.subscriptions.length){
        let subsArray = [];
        //loop over lnpUserInfo subscriptions, which will only have subId and clientSecret props
        for(const sub of response.subscriptions){
          //retrieve expanded detail from stripe to create lnpUserSubscriptions[]
          await get().retrieveSubscriptionById(sub.subscriptionId)
          .then((sub)=>{
            subsArray.push(sub)
          })
        }
        get()._s(s=>{s.lnpUserSubscriptions = subsArray })
      }else{
        get()._s(s=>{s.lnpUserSubscriptions = [{}] })
      }
    }

    const res = fetch
    //
  },
  retrieveSubscriptionById: async (sub_id) => {
    __(`in retrieveSubscriptionById`);
    const response = await fetch(`/api/stripe-retrieve-subscription`, {
      method: "POST",
      body: JSON.stringify({ sub_id }),
      headers: { "Content-Type": "application/json" },
    }).then(async (r)=> {
      const json = await r.json(); return json.message
    });
    console.log(`retrieveSubscriptionById:`,response)
    return response
  },
  retrievePaymentIntent: async (client_secret) => {
    __(`in retrievePaymentIntent`);
    const response = await fetch(`https://api.stripe.com/v1/payment_intents/${client_secret}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }).then(r=>r.json());
    if(response.error){
      return response.error;
    }else if(response.paymentIntent){
      return response.paymentIntent;
    }else{
      console.log('could not retrievePaymentIntent: ', response)
      return false
    }
  },
  // assureCustomer: async () => {
  //   __(`fetching lnpUserInfo`);
  //   await get().user.getIdToken(true)
  //   const user = get().user;
  //   const jwt = user.accessToken
  //   const email = user.email
  //   const uid = user.uid
  //   const response = await fetch(`/api/stripe-assure-customer`, {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({ jwt }),
  //   }).then((r)=> {
  //     return r.json();
  //   });
  //   console.log(`get-lnp-user-info:`,response)
  //   const { lnpUser, customer } = response.message;
  //   if(response!=null && response.subscriptions!=null){
  //     get()._s(s=>{s.lnpUserInfo.subscriptions = response.subscriptions })
  //     get()._s(s=>{s.lnpUserInfo.apiKeys = response.apiKeys })
  //     get()._s(s=>{s.lnpUserInfo.fetchedOnce = true })
  //   }
  // },
  createSubscription: async (product) => {
    __(`in useAuth.createSubscription`);
    await get().user.getIdToken(true)
    const user = get().user;
    const email = user.email
    const jwt = user.accessToken
    const uid = user.uid
    const response = await fetch(`/api/stripe-create-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ jwt,product }),
    }).then(async (r)=> {
      const json = await r.json(); return json.message
    });
    console.log(`/stripe-create-subscription:`,response)
    return response
  },
  cancelSubscription: async (sub_id='') => {
    __(`in useAuth.cancelSubscription`);
    await get().user.getIdToken(true)
    const jwt = get().user.accessToken;
    const response = await fetch(`/api/stripe-cancel-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ jwt, sub_id }),
    }).then(async (r)=> {const json = await r.json();return json.message});
    console.log(`/stripe-cancel-subscription:`,response)
    return response
  },
  changeSubscription: async (product) => {
    __(`in useAuth.changeSubscription`);
    await get().user.getIdToken(true)
    const jwt = get().user.accessToken
    const response = await fetch(`/api/stripe-change-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ jwt, product }),
    }).then(async (r)=> {
      const json = await r.json();
      return json.message
    });
    console.log(`/stripe-change-subscription:`,response)
    return response
  },
  createSetupIntent: async () => {
    __(`in useAuth.createSetupIntent`);
    await get().user.getIdToken(true)
    const user = get().user;
    const jwt = user.accessToken
    const response = await fetch(`/api/stripe-create-setup-intent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ jwt }),
    }).then(async (r)=> {
      const json = await r.json(); return json.message
    });
    console.log(`/stripe-create-setup-intent:`,response)
    return response
  },
  generateNewApiKey: async () => {
    __(`in useAuth.generateNewApiKey`);
    await get().user.getIdToken(true)
    const jwt = get().user.accessToken
    const response = await fetch(`/api/generate-new-api-key`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ jwt }),
    }).then(async (r)=> {
      const json = await r.json();
      return json.message
    });
    console.log(`/generate-new-api-key:`,response)
    set({lnpUserNewApiKey:response})
    return response
  },
  expandSubscriptionRow: (idxToExpand)=>{
    for(let i = 0; i<get().lnpUserSubscriptions.length; i++){
      get()._s(s=>{
        s.lnpUserSubscriptions[i].expand = (i===idxToExpand)
      })
    }
  },
  _s: (fn) => set(produce(fn)),
}));

export const authState = ()=>useAuth.getState();
