import {
  Box,
  Button, Card, CardBody, CardFooter, CardHeader,
  Flex,
  Grid,
  Heading,
  HStack,
  Image,
  Portal, SimpleGrid,
  Stack,
  Text,
  useTheme, chakra, Divider, Spacer,
} from '@chakra-ui/react';
import React from 'react';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import AppFooter from './navs/AppFooter.js';
import ImageArchitect1 from "assets/img/ImageArchitect1.png";
import { VFlexCC } from './bits/UtilityTags.js';

export default function PgLanding() {
  const nav = useNavigate()
  const theme = useTheme()
  const limitWidth = {
    base:'98%',
    md:'668px',
    lg:'860px',
  }
  const handleArchiveDesign = ()=>{

  }
  return (
    // <Flex flexDirection="column" flexGrow='1' pt="75px">

    <>
      <Flex w='100%' h='90px' bgColor='gray.50'  boxShadow='0px 0px 4px 4px #FFFFFF' zIndex='1'/>
      <VFlexCC w='100%' id='top' bgColor='gray.200'>
        <SimpleGrid w={limitWidth} columns={[1,1,1,2]} my={10}>
          <Box>
            <Heading>Welcome to CloudCal</Heading>
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nisi mauris, porta
              vitae elit non, fermentum imperdiet sem. Sed risus enim, vulputate non dui et, ornare
              porttitor augue. Morbi sit amet sapien eget felis condimentum venenatis at vitae
              turpis. Duis placerat et neque id tristique. Donec at elit egestas metus faucibus
              suscipit. Sed pretium ipsum ac velit dapibus volutpat. Nam vulputate congue ligula vel
              pulvinar. Morbi vel feugiat ex.
            </Text>
          </Box>
          <Image src={ImageArchitect1}/>
        </SimpleGrid>
      </VFlexCC>

      <AppFooter />


    </>
  );
}
