import { Flex, Grid, Heading, HStack, Text, } from '@chakra-ui/react';
import React from 'react';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

export default function PgDash() {
  const nav = useNavigate()
  const handleEditDesign = ()=>{
    nav('/design-editor')
  }
  const handleArchiveDesign = ()=>{

  }
  return (
    // <Flex flexDirection="column" flexGrow='1' pt="75px">

    <>
        {/* <BogGlobalStats/> */}

      <Heading>Dashboard</Heading>
    </>
  );
}
