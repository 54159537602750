import React, { useEffect } from 'react';
import { ErrorMessage, useField } from 'formik';
import {
  Box, FormControl, FormErrorMessage, FormLabel, HStack, useRadio, useRadioGroup,
} from '@chakra-ui/react';
import TextError from './TextError.js';


export function FmRadioButtons(props) {
  const {
    label, name, options, isRequired=false, isNA=false, ...rest
  } = props
  const [field, meta, helpers] = useField(name);
  useEffect(()=>{
    helpers.setValue(isNA?'N/A':meta.initialValue)
  },[isNA])

  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    name,
    defaultValue: "",
    onChange: (e)=>{
      helpers.setValue(e);
    }
  });
  useEffect(()=>{
    setValue(meta.initialValue)
  },[])

  const group = getRootProps();
  return (
    <FormControl isRequired isInvalid={meta.touched && !!meta.error} {...rest}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      {isNA && (<Box color='gray.400'>N/A</Box>)}
      {!isNA && (
        <HStack {...group}>
          {options.map(({ key, value }) => (
            <FmRadio key={key} {...getRadioProps({ value: value })}>
              {key}
            </FmRadio>
          ))}
        </HStack>
      )}
      <ErrorMessage as={FormErrorMessage} component={TextError} name={name} />
    </FormControl>
  );
}
const sxFmRadio = {
  bgColor:'bog.500',
  borderWidth:'1px',
  borderColor:'gray.400',
  borderRadius:'6px',
  p: '0.4rem',
  fontSize:'11px',
  color:'bog.100',
  cursor:'pointer',
  _checked:{
    color:'bog.900',
    bgColor: "bog.100",
    cursor:'unset',
    _hover:{ bgColor: "bog.100" },
  },
  _hover:{ bgColor: "bog.300" },
}
function FmRadio(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box {...checkbox} sx={sxFmRadio} >
        {props.children}
      </Box>
    </Box>
  );
}
