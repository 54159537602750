import React, { useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Box, Button, SimpleGrid } from '@chakra-ui/react';
import short from 'short-uuid';
import FmInputText from './FmInputText.js';
import FmInputDate from './FmInputDate.js';
import FmTextarea from './FmTextarea.js';
import FmSelect from './FmSelect.js';
import FmFileUpload from './FmFileUpload.js';
import { BtnXs, TextXs } from '../bits/UtilityTags.js';
import { FmRadioButtons } from './FmRadioButtons.js';
import { FmMultiSelect } from './FmMultiSelect.js';
import { FmTimeIntervalCombo } from './FmTimeIntervalCombo.js';
import FmInputNumber from './FmInputNumber.js';
import axios from 'axios';
import { SuccessModal } from './SuccessModal.js';
import { useAppStore } from '../../services/useAppStore.js';
import { clbRecordShema } from './toolSchema.js';

export default function FormikFormEditTCR({tcrIdx}){
  const data = useAppStore(s=>s.toolProfileData);

  const suid = data.suid
  const [submitComplete, setSubmitComplete] = useState(false)

  const initialValues = data.clbRecords[tcrIdx];

  const onSubmit = async (values, formik) => {
    const payload = {
      suid,
      tcr: values,
      tcrIdx: tcrIdx
    }
    console.log('payload', payload);
    const res = await axios.post('/api/tcr-edit',JSON.stringify(payload)).catch(e=>'fail')
    if(res && res.data === 'success') {
      setSubmitComplete(true)
      formik.resetForm()
      formik.setStatus('Success')
    } else {
      formik.setStatus('Something went wrong.  Try again?');
    }
    formik.setSubmitting(false)
  }

  return (<>
    {!submitComplete && <Formik
      initialValues={initialValues}
      validationSchema={clbRecordShema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          values,
          setFieldValue,
          handleChange,
          handleSubmit,
          isSubmitting
        } = formik;
        const isClbTypeEstablished = values.clbType !== '';
        const isRefOnly = values.clbType === 'refonly';
        const isCondRcvdOOT = values.clbRecords[0].clbCondRcvd === 'outOfTolerance';
        return (
          <form onSubmit={handleSubmit}>

              <Box gridColumn={{
                base: 'span 1',
                md: 'span 2'
              }} sx={{
                backgroundColor: 'brand.ltBlueBg',
                borderRadius: '1rem',
                p: '1rem',
              }}>
                <TextXs mb={3}>Edit a Calibration Record</TextXs>
                <SimpleGrid
                  columns={[1, 1, 1, 2]} spacing="20px" justifyContent={'space-between'}
                >
                  <FmSelect label="Calibration Type" name="clbType"
                            isRequired
                            options={[
                              {key: 'Select an option',value: ''},
                              {key: 'Internal',value: 'internal'},
                              {key: 'Outsourced',value: 'outsourced'},
                              {key: 'Reference Only',value: 'refonly'}
                            ]}/><Box/>
                  {isClbTypeEstablished && (<>
                    <FmInputDate name="clbOnDate" label="Calibrated On Date"
                                 isNA={isRefOnly} isRequired/>
                    <FmInputDate name="clbDueDate" label="Calibration Due Date"
                                 isNA={isRefOnly} isRequired/>
                    <FmTimeIntervalCombo
                      name="clbIval" label="Calibration Interval"
                      hint="Calibrate this tool every"
                      isNA={isRefOnly} isRequired
                    />
                    <Box>
                      <FmSelect
                        name="clbCondRcvd" label="Condition Received"
                        isRequired isNA={isRefOnly}
                        options={[
                          { key: 'Select an option', value: '' },
                          { key: 'In Tolerance', value: 'inTolerance' },
                          { key: 'Out of Tolerance', value: 'outOfTolerance' },
                        ]}/>
                      {isCondRcvdOOT && (
                        <BtnXs onClick={()=>useAppStore.getState().generateOOTReport()}>
                          Generate Out of Tolerance Report PDF
                        </BtnXs>
                      )}
                    </Box>

                    <FmInputText name="clbAgent" label="Calibration Agent"
                                 isNA={isRefOnly} isRequired/>
                    <FmInputText name="clbCertNumber" label="Certification Number"
                                   isNA={isRefOnly} isRequired readOnly disabled/>

                    <FmFileUpload
                      name="clbAttachmentS3FN"
                      label="Attachments for this Calibration Record"
                      isNA={isRefOnly} suid={suid} accept={['image', 'office']}
                    />

                  </>)}

                </SimpleGrid>
                <Button isLoading={isSubmitting} type="submit" my={5} w={40}>Submit</Button>
                <Box>{formik.status}</Box>
              </Box>
          </form>
        );
      }}
    </Formik>}
    </>
  );
};
