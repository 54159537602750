import React, { useState } from 'react';
import { Grid, Heading, } from '@chakra-ui/react';
import ToolListTable from '../components/Tables/ToolListTable.js';

export default function PgToolList() {
  const [perPageLimit, setPerPageLimit] = useState('');
  const [currentPage, setCurrentPage] = useState('');

  return (
    <>
      <Heading>PgToolList</Heading>
      <ToolListTable />
    </>
  );
}
