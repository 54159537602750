import create from 'zustand';
import produce from 'immer';
import { makeOOTRPdf } from '../components/OOT-Report/ootr-pdfmake.js';

export const useAppStore = create((set,get) => ({
  toolProfileData: {},
  clbRecordData: {},
  appNavDrawerOpen:false,
  set_appNavDrawerOpen: async (bOpen) => {
    set({appNavDrawerOpen:bOpen});
  },

  addToolSuccessModalIsOpen:false,
  addToolSuccessLink:'',
  set_addToolSuccessModalIsOpen: (bOpen) => {
    set({addToolSuccessModalIsOpen:bOpen});
  },

  authModalIsOpen:false,
  authModalTabIndex: 0,
  set_authModalIsOpen: async (bOpen, openTab) => {
    if(openTab){
      set({
        authModalTabIndex: {
          'login':0,
          'signup':1,
        }[openTab]
      })
    }
    set({authModalIsOpen:bOpen});
  },
  generateOOTReport: async (bOpen, openTab) => {
    makeOOTRPdf()
  },

  _s: (fn) => set(produce(fn)),
}))

