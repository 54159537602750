import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Center,
  CircularProgress,
  FormControl, FormErrorMessage, FormLabel,
  Icon,
  ListItem,
  UnorderedList
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { AiFillFileAdd } from 'react-icons/ai';
import { BtnXs, TextXs } from '../bits/UtilityTags.js';
import axios from 'axios';
import { animate } from 'framer-motion';
import S3Img from '../../components/FileUpload/S3Img.js';
import { ErrorMessage, useField } from 'formik';
import TextError from './TextError.js';
import { authState } from '../../services/useAuth.js';
import { serverOrigin } from '../../data/constants.js';

export const animatePct = (duration=2, cb)=>{
  animate(0, 100, { duration: duration, onUpdate: v=>cb(v) })
}
export default function FmFileUpload({
  name, label, imgPreviews=false, isNA=false, suid,
  accept=['image','office','archive','dev'],
  ...rest
}) {
  const [field, meta, helpers] = useField(name);
  useEffect(()=>{
    helpers.setValue(isNA?[]:meta.initialValue)
  },[isNA])

  const activeBg = 'bog.100';
  const borderColor = isDragActive ? 'bog.300' : 'bog.300';

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [progressDisplay, setProgressDisplay] = useState('none');
  const [errDisplay, setErrDisplay] = useState('none');
  const [progressPct, setProgressPct] = useState(0);

  const onDrop = async (acceptedFiles) => {
    setProgressPct(0);
    setProgressDisplay('block');
    setErrDisplay('none');

    let formData = new FormData();
    let duration = 0;
    // Instead of setting the files' data to the formik values we go ahead
    // and upload the files.  The server then stores a translation in our
    // DB between the actual S3 filename (uuid added) and the original
    // filename of the upload) which is used for retrievals/downloads.
    // This way the actual S3 filename is never exposed, and yet we still
    // have a human readable/recognizable filename for reference in the UI.
    for(let file of acceptedFiles){
      duration =+ file.size / 100000;
      formData.append('file',file);
    }
    formData.append('suid', suid)
    animatePct(duration,(v)=>setProgressPct(v));

    const res = await axios.post(`${serverOrigin}/upload`, formData )
    .catch(console.error);
    console.log(res);

    if(res && res.data.status === 'success' && res.data.results != null) {
      const modded = field.value.concat(res.data.results)
      helpers.setValue(modded);
      setUploadedFiles(modded);
      setProgressDisplay('none');
    } else {
      setProgressDisplay('none');
      setErrDisplay('block');
    }

  };

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, } = useDropzone({
    onDrop, maxFiles: 5, multiple: true,
    accept: rdzAcceptTypes(accept)
  });

  const removeFile = async (suid, originalname)=>{
    setProgressDisplay('block')
    const res = await authState().removeAttachment(suid, originalname)

    if(res) {
      const modded = field.value.filter(v=>v.originalname !== originalname )
      helpers.setValue(modded);
      setUploadedFiles(modded);
      setProgressDisplay('none');
    } else {
      setProgressDisplay('none');
      setErrDisplay('block');
    }
  }
  const uploadedFilesPartial = useMemo(()=>uploadedFiles.map((file,i) => (
    <ListItem key={file.originalname}>
      {file.originalname} - {file.size} bytes
      {file.mimetype.indexOf('image')>-1 && imgPreviews && (
        <S3Img suid={suid} filename={file.originalname}
               onRemove={()=>removeFile(suid, file.originalname)}/>
      )}
    </ListItem>
  )), [uploadedFiles]);

  // useEffect(()=>{
  //   setFiles(uploadedFiles);
  // },[uploadedFiles])

  return (
    <FormControl {...rest}>
      <FormLabel>{label}</FormLabel>
      {isNA && (<Box color='gray.400'>N/A</Box>)}
      {!isNA && (<>
          <Center
            p={1}
            cursor="pointer"
            bg={isDragActive ? activeBg : 'transparent'}
            _hover={{ bg: activeBg }}
            transition="background-color 0.2s ease"
            borderRadius={4}
            border="2px dashed"
            borderColor={borderColor}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Icon as={AiFillFileAdd} mr={2} />
            <p>Drag 'n' drop or click here to select files</p>
          </Center>
          <aside>
            {!!uploadedFiles.length && (<TextXs>Accepted files:</TextXs>)}
            <UnorderedList style={{}}>{uploadedFilesPartial}</UnorderedList>
            <TextXs display={errDisplay} color={'red'}>Something went wrong. Please try again.</TextXs>
            <CircularProgress
              value={progressPct} isIndeterminate={progressPct===0}
              display={progressDisplay} thickness='16px' size='16px'
              color='blue.300'
            />
          </aside>
        </>
      )}
      <ErrorMessage as={FormErrorMessage} component={TextError} name={name} />
    </FormControl>
  );
}

export const filetypeGroups = {
  image:{
    'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.bmp'],
  },
  office:{
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'text/plain': ['.txt'],
    'application/pdf': ['.pdf'],
    'application/rtf': ['.rtf'],
    'application/epub+zip': ['.epub'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'text/csv': ['.csv'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  },
  archive:{
    'application/vnd.rar': ['.rar'],
    'application/x-tar': ['.tar'],
    'application/x-7z-compressed': ['.7z'],
    'application/zip': ['.zip'],
  },
  dev:{
    'application/json': ['.json'],
    'application/xml': ['.xml'],
  },
}

export const rdzAcceptTypes = (aTypes = ['image', 'office', 'archive', 'dev']) => {
  const rv = {};
  if (aTypes.indexOf('image') > -1) {
    Object.assign(rv, filetypeGroups.image);
  }
  if (aTypes.indexOf('office') > -1) {
    Object.assign(rv, filetypeGroups.office);
  }
  if (aTypes.indexOf('archive') > -1) {
    Object.assign(rv, filetypeGroups.archive);
  }
  if (aTypes.indexOf('dev') > -1) {
    Object.assign(rv, filetypeGroups.dev);
  }
  return rv;
};
