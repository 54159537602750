import React from 'react';
import { Grid, Heading, } from '@chakra-ui/react';


export default function PgCalibrationAdd() {

  return (
    <>
      <Heading>PgCalibrationAdd</Heading>
    </>
  );
}
