import React, { useEffect } from 'react';
import { ErrorMessage, Field, useField } from 'formik';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Text
} from '@chakra-ui/react';
import TextError from './TextError.js';
import { MultiSelect } from 'chakra-multiselect';
import { HFlexSC, S } from '../bits/UtilityTags.js';

export function FmTimeIntervalCombo (props) {
  const { label, hint, name, options, formik,
    isRequired=false, isNA=false, ...rest } = props

  const [field, meta, helpers] = useField(name);
  useEffect(()=>{
    helpers.setValue(isNA?['N/A']:meta.initialValue)
  },[isNA])

  return (
    <FormControl isRequired={isRequired && !isNA} >
      <FormLabel>{label}</FormLabel>
      {isNA && (<Box color='gray.400'>N/A</Box>)}
      {!isNA && (
      <HFlexSC>
        <S fontSize={11}>{hint}</S>
        <Input type="number" placeholder='#' w={20} ml={3} value={field.value[0]}
               onChange={(e)=>{
                 helpers.setValue([e.target.value, field.value[1]]);
               }} />
        <Select placeholder='' value={field.value[1]} w={36}
                onChange={(e)=>{
                  helpers.setValue([field.value[0], e.target.value]);
                }}>
          <option value=''/>
          <option value='d'>Day(s)</option>
          <option value='m'>Month(s)</option>
          <option value='y'>Year(s)</option>
        </Select>
      </HFlexSC>
      )}
      <ErrorMessage as={FormErrorMessage} component={TextError} name={name} />
    </FormControl>
  )
}
