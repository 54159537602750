import { Link } from "react-router-dom";
import { VFlex } from './bits/UtilityTags.js';

export default function PgNotFound() {
  return (
    <VFlex>
      <h1>Oops! You seem to be lost.</h1>
      <p>Here are some helpful links:</p>
      <Link to='/'>Home</Link>
      <Link to='/docs'>Docs</Link>
    </VFlex>
  )
}
