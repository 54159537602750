import * as Yup from 'yup';

export const clbIvalTest = (value) => {
  if(value.length===1 && value[0]==='N/A'){return true}
  else if (!Yup.number().isValidSync(value[0])) {
    return false;
  } else if (!Yup.string().oneOf(['d','m','y',]).isValidSync(value[1])) {
    return false;
  }else{ return true; }
};

export const toolAttachmentShape = {
  mimetype: Yup.string().required(),
  originalname: Yup.string().required(),
  size: Yup.number().required(),
  status: Yup.string().required(),
}

export const clbRecordShape = {
  clbType: Yup.string().required('Required'),
  clbOnDate: Yup.string().required('Required'),
  clbDueDate: Yup.string().required('Required'),
  clbIval: Yup.array().of(Yup.string().required()).test(
    "clbIvalTest", "not valid", clbIvalTest
  ).required('Required'),
  // ffg: Yup.lazy(val => (Array.isArray(val) ? Yup.array().of(Yup.string()) : Yup.string())),
  clbCondRcvd: Yup.string().required('Required'),
  clbAgent: Yup.string().required('Required'),
  clbCertNumber: Yup.string().required('Required'),
  clbAttachmentS3FN: Yup.array().of(
    Yup.object().shape(toolAttachmentShape)
  ).required('Required').nullable(),
}
export const clbRecordShema = Yup.object(clbRecordShape)
export const toolPropShape = {
  toolId: Yup.string().required('Required'),
  inServiceDate: Yup.string().required('Required'),
  toolStatus: Yup.string().required('Required')
  .oneOf(['active','outForCalibration','inactive',]),
  toolImageS3FN: Yup.array().of(
    Yup.object().shape(toolAttachmentShape)
  ).required('Required').nullable(),
  description: Yup.string().required('Required'),
  modelNumber: Yup.string().required('Required'),
  serialNumber: Yup.string().required('Required'),
  clbRecords: Yup.array().of(Yup.object().shape(clbRecordShape))
  .required('Required'),
  toolLocation: Yup.array()
  .min(1, 'Provide at least one location')
  .of(
    Yup.string().oneOf(['Inspection','Production','Alaska',])
  ).required('Provide at least one location'),
  comments: Yup.string(),
}
export const toolSchema = Yup.object(toolPropShape);
