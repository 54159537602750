import React, { useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Box, Button, SimpleGrid } from '@chakra-ui/react';
import short from 'short-uuid';
import FmInputText from './FmInputText.js';
import FmInputDate from './FmInputDate.js';
import FmTextarea from './FmTextarea.js';
import FmSelect from './FmSelect.js';
import FmFileUpload from './FmFileUpload.js';
import { BtnXs, TextXs } from '../bits/UtilityTags.js';
import { FmRadioButtons } from './FmRadioButtons.js';
import { FmMultiSelect } from './FmMultiSelect.js';
import { FmTimeIntervalCombo } from './FmTimeIntervalCombo.js';
import FmInputNumber from './FmInputNumber.js';
import axios from 'axios';
import { SuccessModal } from './SuccessModal.js';
import { useAppStore } from '../../services/useAppStore.js';
import { toolSchema } from './toolSchema.js';

Date.prototype.toDateInputValue = (function() {
  const local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0,10);
});

const initialValuesMock = {
  toolId: 'ach30',
  inServiceDate: '2023-02-13',
  toolStatus: 'active',
  toolImageS3FN: [],
  description: 'mydescription',
  modelNumber: '111222333',
  serialNumber: '444555666',
  clbRecords: [
    {
      clbType: 'refonly',
      clbOnDate: '2022-02-28',
      clbDueDate: '2024-04-30',
      clbIval: ['3','m'],
      clbCondRcvd: 'inTolerance',
      clbAgent: 'Mark',
      clbCertNumber: '777888999',
      clbAttachmentS3FN: [],
    }
  ],
  comments: 'mycomment',
  toolLocation: ['Alaska'],
}
const initialValues = {
  toolId: '',
  inServiceDate: new Date().toDateInputValue(),
  toolStatus: 'active',
  toolImageS3FN: [],
  description: '',
  modelNumber: '',
  serialNumber: '',
  clbRecords: [
    {
      clbType: '',
      clbOnDate: '',
      clbDueDate: '',
      clbIval: ['',''],
      clbCondRcvd: '',
      clbAgent: '',
      clbCertNumber: '',
      clbAttachmentS3FN: [],
    }
  ],
  comments: '',
  toolLocation: [],
};
export default function FormikFormAddTool(){
  const [suid, setSuid] = useState(useMemo(()=>short.generate(),[]))
  const [submitComplete, setSubmitComplete] = useState(false)


  const onSubmit = async (values, formik) => {
    // for (let i = 0; i <= values.attachments.length; i++) {
    //   formData.append(`attachments[${i}]`, values.attachments[i]);
    // }
    console.log('Form data', values);
    values.suid = suid;
    const res = await axios.post('/api/tool-add',JSON.stringify(values)).catch(e=>'fail')
    // const res = await axios.post('http://localhost:4040/v1/epic/create-epic',JSON.stringify(mockCreateEpic))
    if(res && res.data === 'success') {
      setSubmitComplete(true)
      formik.resetForm()
      formik.setStatus('Success')
      useAppStore.setState({addToolSuccessLink: `/tool-profile/?suid=${suid}` })
      useAppStore.getState().set_addToolSuccessModalIsOpen(true)
    } else {
      formik.setStatus('Something went wrong.  Try again?');
    }
    formik.setSubmitting(false)
  }

  return (<>
    {!submitComplete && <Formik
      initialValues={initialValues}
      validationSchema={toolSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          values, setFieldValue, handleChange, handleSubmit, isSubmitting
        } = formik;
        const isClbTypeEstablished = values.clbRecords[0].clbType !== '';
        const isRefOnly = values.clbRecords[0].clbType === 'refonly';
        const isCondRcvdOOT = values.clbRecords[0].clbCondRcvd === 'outOfTolerance';
        return (
          <form onSubmit={handleSubmit}>

            <SimpleGrid mt={2} columns={[1, 1, 1, 2]} spacing="20px">


              <FmInputText name="toolId" label="Tool ID" isRequired/>
              <FmInputDate name="inServiceDate" label="Put In Service On Date" isRequired/>
              <FmRadioButtons
                name="toolStatus" label="Tool Status" isRequired
                options={[
                  { key: 'Active', value: 'active' },
                  { key: 'Out for Calibration', value: 'outForCalibration' },
                  { key: 'Inactive', value: 'inactive' }
                ]}/>
              <FmFileUpload
                label="Tool Images"
                name="toolImageS3FN" suid={suid}
                accept={['image']}
                imgPreviews
              />
              <FmTextarea name="description" label="Description" isRequired/>
              <FmInputText name="modelNumber" label="Model Number" isRequired/>
              <FmInputText name="serialNumber" label="Serial Number" isRequired/>


              <Box gridColumn={{ base: 'span 1', md: 'span 2' }} sx={{
                backgroundColor: 'brand.ltBlueBg',
                borderRadius: '1rem', p: '1rem', mt: '2rem'
              }}>
                <TextXs mb={3}>Add a Calibration Record</TextXs>
                <SimpleGrid
                  columns={[1, 1, 1, 2]} spacing="20px" justifyContent={'space-between'}
                >
                  <FmSelect label="Calibration Type" name="clbRecords.0.clbType"
                            isRequired
                            options={[
                              { key: 'Select an option', value: '' },
                              { key: 'Internal', value: 'internal' },
                              { key: 'Outsourced', value: 'outsourced' },
                              { key: 'Reference Only', value: 'refonly' }
                            ]}/><Box/>
                  {isClbTypeEstablished && (<>
                    <FmInputDate name="clbRecords.0.clbOnDate" label="Calibrated On Date"
                                 isNA={isRefOnly} isRequired/>
                    <FmInputDate name="clbRecords.0.clbDueDate" label="Calibration Due Date"
                                 isNA={isRefOnly} isRequired/>
                    <FmTimeIntervalCombo
                      name="clbRecords.0.clbIval" label="Calibration Interval"
                      hint="Calibrate this tool every"
                      isNA={isRefOnly} isRequired
                    />
                    <Box>
                      <FmSelect
                        name="clbRecords.0.clbCondRcvd" label="Condition Received"
                        isRequired isNA={isRefOnly}
                        options={[
                          { key: 'Select an option', value: '' },
                          { key: 'In Tolerance', value: 'inTolerance' },
                          { key: 'Out of Tolerance', value: 'outOfTolerance' },
                        ]}/>
                      {isCondRcvdOOT && (
                        <BtnXs onClick={()=>useAppStore.getState().generateOOTReport()}>
                          Generate Out of Tolerance Report PDF
                        </BtnXs>
                      )}
                    </Box>
                    <FmInputText name="clbRecords.0.clbAgent" label="Calibration Agent"
                                 isNA={isRefOnly} isRequired/>
                    <FmInputText name="clbRecords.0.clbCertNumber" label="Certification Number"
                                   isNA={isRefOnly} isRequired/>

                    <FmFileUpload
                      name="clbRecords.0.clbAttachmentS3FN"
                      label="Attachments for this Calibration Record"
                      isNA={isRefOnly} suid={suid} accept={['image', 'office']}
                    />

                  </>)}

                </SimpleGrid>

              </Box>

              <FmMultiSelect
                name="toolLocation" label="Tool Location" isRequired
                options={['Inspection', 'Production', 'Alaska']}
              />
              <FmTextarea name="comments" label="Comments"/>
            </SimpleGrid>


            <Button isLoading={isSubmitting} type="submit" my={5} w={40}>Submit</Button>
            <Box>{formik.status}</Box>
          </form>
        );
      }}
    </Formik>}
      <SuccessModal/>
    </>
  );
};
