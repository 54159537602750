import {
  Button, Input,
  Modal, ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { useAppStore } from 'services/useAppStore.js';
import { authState, useAuth } from 'services/useAuth.js';
import { HFlexSC, TextXs, VFlex } from '../bits/UtilityTags.js';
import { mont } from '../../theme/foundations/fonts.js';
import { NavLink, useNavigate } from 'react-router-dom';
import { CopyToClipboardButton } from '../../hooks/CTCBButton.js';


export function SuccessModal({  }) {
  const nav = useNavigate()
  const isOpen = useAppStore(s=>s.addToolSuccessModalIsOpen)
  const addToolSuccessLink = useAppStore(s=>s.addToolSuccessLink)
  const txtarea = useRef(null)
  const onClose = ()=>{
    useAuth.setState({addToolSuccessLink: ''})
    useAppStore.getState().set_addToolSuccessModalIsOpen(false)
    nav('/tool-list')
  }
  const onGotoNewURL = ()=>{
    useAuth.setState({addToolSuccessLink: ''})
    useAppStore.getState().set_addToolSuccessModalIsOpen(false)
    //`/tool-profile/?suid=${suid}`
    nav(addToolSuccessLink)
  }

  return (
    <>
      <Modal isOpen={isOpen} onBlur={onClose} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Success!</ModalHeader>
          <ModalBody>
            {addToolSuccessLink && (
              <VFlex gap={2}>
                <TextXs>Here is the new page URL for this tool:</TextXs>
                <Input type='text' value={location.origin + addToolSuccessLink} readOnly/>
                <CopyToClipboardButton text={addToolSuccessLink} sx={{
                  backgroundColor: 'gray.100',
                  border: '1px solid',
                  borderColor: 'gray.500',
                  w:'80px',
                  h:'30px',
                  justifyContent:'center',
                  alignItems:'center',
                  ...mont.md.md
                }}/>
                <Button onClick={onGotoNewURL}>Go there now</Button>
              </VFlex>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

