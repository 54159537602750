import { Box, Button, Icon, Select, } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import {
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import DebouncedInput from './ReactTable/DebouncedInput.js';
import { fuzzyFilter } from './ReactTable/filters.js';
import { PaginationButtons } from './PaginationButtons.js';
import { MdOpenInFull } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { ColFilter } from './ColFilter.js';

const fns = require('date-fns')

export default function ToolListTable(props) {
  const rerender = React.useReducer(() => ({}), {})[1]

  const [numDocs, setNumDocs] = React.useState({});
  const [numPages, setNumPages] = React.useState({});
  const [data, setData] = React.useState({});
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState('');

  const fetchTools = async ()=>{
    const response = await fetch(`/api/tool-list?limit=5&page=1`).then((r)=>r.json());
    setNumDocs(response.numDocs);
    setNumPages(response.numPages);
    setData(response.tools);
  }
  useEffect(() => {
    fetchTools();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'toolId',
        header: 'Tool ID',
        cell: info => info.getValue(),
      },
      // {
      //   accessorKey: 'suid',
      //   header: 'SUID',
      //   cell: info => info.getValue(),
      // },
      {
        accessorKey: 'toolStatus',
        header: 'Status',
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'modelNumber',
        header: 'Model No.',
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'serialNumber',
        header: 'Serial No.',
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'calibrationType',
        header: 'Calib. Type',
        cell: info => info.getValue(),
      },
      {
        id: 'inServiceDate',//required with accessorFn
        header: 'In Service Date',
        accessorFn: row => {
          return fns.format(new Date(row.inServiceDate), 'dd/MM/yyyy')
        },
        cell: info => info.getValue(),
      },
      {
        header: 'Actions',
        accessorFn: row => {
          return row.suid;
        },
        cell: info => {
          const uri = '/tool-profile/?suid='+info.getValue();
          return (<>
            <NavLink to={uri}>
              <Button size={'sm'}>
                <Icon as={MdOpenInFull} w={'100%'}/>
              </Button>
            </NavLink>
          </>)
        },
      },
      // {
      //   accessorFn: row => `${row.firstName} ${row.lastName}`,
      //   id: 'fullName',
      //   header:  () => <span>Full Name</span>,
      //   cell: info => info.getValue(),
      //   footer: props => props.column.id,
      //   filterFn: 'fuzzy',
      //   sortingFn: fuzzySort,
      // },
    ],
    []
  )

  const refreshData = () => setData(()=> {
    return fetchTools();
  })

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: true,
    debugColumns: false,
  })

  useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'fullName') {
      if (table.getState().sorting[0]?.id !== 'fullName') {
        table.setSorting([{ id: 'fullName', desc: false }])
      }
    }
  }, [table.getState().columnFilters[0]?.id])


  return (
    <Styles className="p-2">
      <div>
        <DebouncedInput width='190px'
          value={globalFilter ?? ''}
          onChange={value => setGlobalFilter(String(value))}
          placeholder="Search all columns..."
        />
      </div>
      <div className="h-2" />
      <table>
        <thead>
        {table.getHeaderGroups().map((headerGroup, i) => (
          <tr key={headerGroup.id + i}>
            {headerGroup.headers.map((header, ii) => {
              return (
                <th key={header.id + ii} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <>
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                      {header.column.getCanFilter() ? (
                        <div>
                          {/* <ColFilter column={header.column} table={table} /> */}
                        </div>
                      ) : null}
                    </>
                  )}
                </th>
              )
            })}
          </tr>
        ))}
        </thead>
        <tbody>
        {table.getRowModel().rows.map(row => {
          return (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => {
                return (
                  <td key={cell.id}>
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  </td>
                )
              })}
            </tr>
          )
        })}
        </tbody>

      </table>
      <PaginationButtons table={table}/>
      <Box h={3}/>
      <Select
        width={'150px'}
        value={table.getState().pagination.pageSize}
        onChange={e => {
          table.setPageSize(Number(e.target.value))
        }}
      >
        {[1, 6, 10, 20, 30, 40, 50].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </Select>
      <div>Total Records: {table.getPrePaginationRowModel().rows.length}</div>
      <div>
        <Button onClick={() => rerender()}>Force Rerender</Button>
      </div>
      <div>
        <Button onClick={() => refreshData()}>Refresh Data</Button>
      </div>
      {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}
    </Styles>
  )
}

const Styles = styled.div`
  padding: 1rem;
  overflow: auto;
  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }

    td {
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`
