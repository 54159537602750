import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Button, Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex, Icon, Image,
  Stack,
} from '@chakra-ui/react';
import IconBox from "components/Icons/IconBox.js";
import { AppSidebarHelp } from "components/Sidebar/AppSidebarHelp.js";
import PropTypes from "prop-types";
import React, { useEffect } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import BrandLogoSvg from 'assets/logos/brand-logo.svg';
import { useDeviceMode } from '../../theme/foundations/breakpoints.js';
import { desktopSidebarWidth } from 'data/constants.js';
import { SBNavLink } from './SBNavLink.js';
import { useAppStore } from '../../services/useAppStore.js';

export function AppSidebar(props) {
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the rest of the collapses
  // verifies if routeName is the one active (in browser input)

  //  Chakra Color Mode
  const mainText = "gray.200"

  const SBCloseButton = ()=>{return(
    <DrawerCloseButton as={Button}
      color={mainText}
      _focus={{ boxShadow: "none" }} _hover={{ boxShadow: "none" }}
    />);
  }

  // SIDEBAR
  const [isMobile, isDesktop] = useDeviceMode()
  const appNavDrawerOpen = useAppStore(state => state.appNavDrawerOpen)

  useEffect(()=>{
    //close drawer on ALL deviceMode changes
    console.log('deviceMode changed');
    useAppStore.getState().set_appNavDrawerOpen(false)
  },[isDesktop])

  const btnRef = React.useRef();
  // Color variables
  return (
    isDesktop? (
        <Flex id='AppSidebarDesktop'
              display="flex"
              alignItems="start"
              justifyContent='center'
              sx={{
                ':before': {
                  content: '" "',
                  display: 'block',
                  position: 'absolute',
                  left: '8px',
                  top: '70px',
                  right: '0',
                  bottom: '10px',
                  borderRadius:'panelsRadius',
                  // width: '100%',
                  // height: '100%',
                  backgroundColor: 'brand.blue3',
                  pointerEvents: 'none',
                },
                position: 'fixed',
                left: '0', top: '0',
                width: desktopSidebarWidth,
                height: '100%'
              }}
        >
          <Box pt={20} textAlign='center' position={'relative'}>
            <NavLink to='./'><Flex as={Image} w={'100%'} h={10} src={BrandLogoSvg}/></NavLink>
            <Stack m={5} direction="column" flexFlow={'wrap'} gap='5px'>
              <SBNavLink to='./dash'>Dashboard</SBNavLink>
              <SBNavLink to='./tool-add'>tool-add</SBNavLink>
              <SBNavLink to='./tool-list'>tool-list</SBNavLink>
              <SBNavLink to='./tool-profile'>tool-profile</SBNavLink>
              <SBNavLink to='./calibration-add'>calibration-add</SBNavLink>
              <SBNavLink to='./settings'>settings</SBNavLink>
            </Stack>
          </Box>

        </Flex>
      ) : (
        <Drawer isOpen={isDesktop?false:appNavDrawerOpen}
                onClose={() => useAppStore.getState().set_appNavDrawerOpen(false)}
                finalFocusRef={btnRef} placement="right">
          <DrawerOverlay backdropFilter="saturate(200%) blur(3px)"/>
          <DrawerContent style={{background:'transparent',borderRadius:'9px',
            top:'7px', right:'7px', bottom:'auto', width:'11rem',
          }}>
            <DrawerBody px="1rem">
              <SBCloseButton/>
              <Box pt={8} textAlign='center'>
                <NavLink to='./'><Flex as={Image} w={'100%'} h={10} src={BrandLogoSvg}/></NavLink>
                <Stack my={5} direction="column" alignItems="center">
                  <SBNavLink to='./dash'>Dashboard</SBNavLink>
                  <SBNavLink to='./tool-add'>tool-add</SBNavLink>
                  <SBNavLink to='./tool-list'>tool-list</SBNavLink>
                  <SBNavLink to='./tool-profile'>tool-profile</SBNavLink>
                  <SBNavLink to='./calibration-add'>calibration-add</SBNavLink>
                  <SBNavLink to='./settings'>settings</SBNavLink>
                </Stack>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )
  );
}

// PROPS

AppSidebar.propTypes = {};
export default AppSidebar;

