import React, { useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Box, Button, SimpleGrid } from '@chakra-ui/react';
import short from 'short-uuid';
import FmInputText from './FmInputText.js';
import FmInputDate from './FmInputDate.js';
import FmTextarea from './FmTextarea.js';
import FmSelect from './FmSelect.js';
import FmFileUpload from './FmFileUpload.js';
import { TextXs } from '../bits/UtilityTags.js';
import { FmRadioButtons } from './FmRadioButtons.js';
import { FmMultiSelect } from './FmMultiSelect.js';
import { FmTimeIntervalCombo } from './FmTimeIntervalCombo.js';
import FmInputNumber from './FmInputNumber.js';
import axios from 'axios';
import { SuccessModal } from './SuccessModal.js';
import { useAppStore } from '../../services/useAppStore.js';
import { toolSchema } from './toolSchema.js';

export default function FormikFormEditTool(){
  const data = useAppStore(s=>s.toolProfileData);

  const suid = data.suid
  const [submitComplete, setSubmitComplete] = useState(false)

  const initialValues = data;

  const onSubmit = async (values, formik) => {
    // for (let i = 0; i <= values.attachments.length; i++) {
    //   formData.append(`attachments[${i}]`, values.attachments[i]);
    // }
    console.log('Form data', values);
    values.suid = suid;
    const res = await axios.post('/api/tool-edit',JSON.stringify(values)).catch(e=>'fail')
    if(res && res.data === 'success') {
      setSubmitComplete(true)
      formik.resetForm()
      formik.setStatus('Success')
      useAppStore.setState({addToolSuccessLink: `/tool-profile/?suid=${suid}` })
      useAppStore.getState().set_addToolSuccessModalIsOpen(true)
    } else {
      formik.setStatus('Something went wrong.  Try again?');
    }
    formik.setSubmitting(false)
  }

  return (<>
    {!submitComplete && <Formik
      initialValues={initialValues}
      validationSchema={toolSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          values,
          setFieldValue,
          handleChange,
          handleSubmit,
          isSubmitting
        } = formik;

        return (
          <form onSubmit={handleSubmit}>

            <SimpleGrid mt={2} columns={[1, 1, 1, 2]} spacing="20px">


              <FmInputText name="toolId" label="Tool ID" isRequired/>
              <FmInputDate name="inServiceDate" label="Put In Service On Date" isRequired/>
              <FmRadioButtons
                name="toolStatus" label="Tool Status" isRequired
                options={[
                  { key: 'Active', value: 'active' },
                  { key: 'Out for Calibration', value: 'outForCalibration' },
                  { key: 'Inactive', value: 'inactive' }
                ]}/>
              <FmFileUpload
                label="Tool Images"
                name="toolImageS3FN" suid={suid}
                accept={['image']}
                imgPreviews
              />
              <FmTextarea name="description" label="Description" isRequired/>
              <FmInputText name="modelNumber" label="Model Number" isRequired/>
              <FmInputText name="serialNumber" label="Serial Number" isRequired/>

              <FmMultiSelect
                name="toolLocation" label="Tool Location" isRequired
                options={['Inspection', 'Production', 'Alaska']}
              />
              <FmTextarea name="comments" label="Comments"/>
            </SimpleGrid>


            <Button isLoading={isSubmitting} type="submit" my={5} w={40}>Submit</Button>
            <Box>{formik.status}</Box>
          </form>
        );
      }}
    </Formik>}
      <SuccessModal/>
    </>
  );
};
