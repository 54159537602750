import React, { useEffect } from 'react';
import { Field, ErrorMessage, useField } from 'formik';
import TextError from './TextError';
import { Box, FormControl, FormErrorMessage, FormLabel, Select } from '@chakra-ui/react';

export default function FmSelect(props) {
  const {
    label, name, options, isRequired=false, isNA=false, ...rest
  } = props
  const [field, meta, helpers] = useField(name);
  // if(isNA){helpers.setValue('N/A')}

  useEffect(()=>{
    helpers.setValue(isNA?'N/A':meta.initialValue)
  },[isNA])
  return (
    <FormControl {...rest} isRequired={isRequired}
                 isInvalid={!!meta.error && meta.touched}>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      {isNA && (<Box color='gray.400'>N/A</Box>)}
      {!isNA && (
        <Select {...field}>
          {options.map(option => (
            <option key={option.key} value={option.value}>
              {option.key}
            </option>
          ))}
        </Select>
      )}
      <ErrorMessage as={FormErrorMessage} component={TextError} name={name} />
    </FormControl>
  );
}
