import React from 'react';
import { Grid, Heading, } from '@chakra-ui/react';


export default function PgDocs() {

  return (
    <>
      <Heading>PgDocs</Heading>
    </>
  );
}
