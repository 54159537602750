import React, { useMemo, useState } from 'react';
import {
  Box,
  Button, Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  HStack, IconButton, Image,
  Input, Select, SimpleGrid, Switch, Textarea,
} from '@chakra-ui/react';
import { RadioButtons } from './bits/RadioCard.js';
import axios from 'axios';
import { HFlex, HFlexSC, S, TextXs, VFlex } from './bits/UtilityTags.js';
import { BiPaste } from 'react-icons/bi';
import ImageUploadControl from '../components/FileUpload/ImageUploadControl.js';
import { MultiSelect } from 'chakra-multiselect'
import { breakpoints } from '../theme/foundations/breakpoints.js';
import FileUploadControl from '../components/FileUpload/FileUploadControl.js';
import short from 'short-uuid';
import * as Yup from "yup";
import FormikFormAddTool from './formik/FormikFormAddTool.js';



export default function PgToolAdd() {

  return (
    <VFlex w={{sm:'96%',md:'90%',lg:breakpoints.md}} alignSelf={'start'}>
      <Heading>Add a Tool</Heading>
      <FormikFormAddTool/>
    </VFlex>
  );

}


