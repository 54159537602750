import React, { useEffect, useState } from 'react';
import {
  Box, Button, Grid, Heading, ListItem, SimpleGrid, Spinner, Text, UnorderedList,
  Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, IconButton,
} from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BtnXs, HFlexCC, S, TextXs, VFlex, VFlexCC } from './bits/UtilityTags.js';
import {format} from 'date-fns';
import S3Img from '../components/FileUpload/S3Img.js';
import { breakpoints } from '../theme/foundations/breakpoints.js';
import { authState } from '../services/useAuth.js';
import { EditIcon } from '@chakra-ui/icons';
import { EditableKV } from './formik/EditableKV.js';
import { useAppStore } from '../services/useAppStore.js';
import FormikFormEditTool from './formik/FormikFormEditTool.js';
import FormikFormEditTCR from './formik/FormikFormEditTCR.js';
import FormikFormAddTCR from './formik/FormikFormAddTCR.js';

const editCtrl = {
  toolId: 'ach30',
  inServiceDate: '2021-01-30',
  toolStatus: 'outForCalibration',
  toolImageS3FN: [],
  description: 'mydescription',
  modelNumber: '111222333',
  serialNumber: '444555666',
  clbRecords: [
    {
      clbType: 'refonly',
      clbOnDate: '2022-02-28',
      clbDueDate: '2024-04-30',
      clbIval: ['3','m'],
      clbCondRcvd: 'inTolerance',
      clbAgent: 'Mark',
      clbCertNumber: '777888999',
      clbAttachmentS3FN: [],
    }
  ],
  comments: 'mycomment',
  toolLocation: ['Alaska'],

}
export default function PgToolProfile() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isEditMode, setIsEditMode] = useState(false)
  const [showNewTCRForm, setShowNewTCRForm] = useState(false)
  const data = useAppStore(s=>s.toolProfileData);
  const navigate = useNavigate();
  const fetchTool = async ()=>{
    const toolIdParam = searchParams.get('suid');
    const response = await fetch(`/api/tool-profile?suid=${toolIdParam}`).then((r)=>r.json());
    if(response.tools!=null && response.tools.length){
      useAppStore.setState({toolProfileData: response.tools[0]})
    }
  }
  useEffect(() => {
    if(!searchParams.get('suid')){navigate('/tool-list')}
    fetchTool();
    return ()=>{
      useAppStore.setState({toolProfileData: {}})
    }
  }, []);

  const FilesDisplay = ({label, suid, fileArray})=>{
    const [files, setFiles] = useState(fileArray)
    const removeFile = async (suid, originalname)=>{
      const res = await authState().removeAttachment(suid, originalname)
      if(res) {
        const modded = files.filter(v=>v.originalname !== originalname )
        setFiles(modded);
      }
    }

    return (
      <Box>{label}: <UnorderedList>{files.map((v)=>(
        <ListItem key={v.originalname}>
          {v.originalname} - {v.size} bytes
          {v.mimetype.indexOf('image')>-1 &&
            <S3Img suid={suid} filename={v.originalname}
                   onRemove={()=>removeFile(suid, v.originalname)}/>
          }
        </ListItem>
      ))}</UnorderedList></Box>
    )
  }
  const PropDisplay = ({label, value })=>{
    return (
      <SimpleGrid columns={[1,1,1,2]}>
        <S fontWeight={'600'}>{label}:</S>
        <S>{value}</S>
      </SimpleGrid>
    )
  }
  const TCRAccItem = ({record, idx })=>{
    const [showEditTCR, setShowEditTCR] = useState(false)
    const editTCR = ()=>{

    }
    const deleteTCR = ()=>{

    }
    return (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex='1' textAlign='left'>
              ({idx+1}) - {record.clbOnDate} - {record.clbType}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          {showEditTCR ? (<>
            <BtnXs onClick={()=>{setShowEditTCR(false)}}>Cancel Editing this Record</BtnXs>
            <FormikFormEditTCR tcrIdx={idx}/>
          </>):(<>
            <PropDisplay label='Calibration Type' value={record.clbType}/>
            <PropDisplay label='Calib On Date' value={record.clbOnDate}/>
            <PropDisplay label='Calib Due Date' value={record.clbDueDate}/>
            <PropDisplay label='Calib Ival' value={record.clbIval}/>
            <PropDisplay label='Condition Received' value={record.clbCondRcvd}/>
            <PropDisplay label='Calibration Agent' value={record.clbAgent}/>
            <PropDisplay label='Certificate Number' value={record.clbCertNumber}/>
            <FilesDisplay label='Attachments' suid={data.suid} fileArray={data.clbAttachmentS3FN ?? []}/>
            <BtnXs onClick={()=>{setShowEditTCR(true)}}>Edit this Record</BtnXs>
            <BtnXs onClick={()=>{deleteTCR(idx)}}>Delete this Record</BtnXs>
          </>)}
        </AccordionPanel>
      </AccordionItem>
    )
  }
  return (<VFlex w={{sm:'96%',md:'90%',lg:breakpoints.md}} mb={5} alignSelf={'start'}>
    {Object.entries(data).length ? (<>
      <Heading justifyContent='space-between' display='flex'>
        {data.toolId}
        {isEditMode ? (<>
          <Button onClick={()=>{setIsEditMode(false)}}>Cancel Edit</Button>
        </>) : (<>
          <Button onClick={()=>{setIsEditMode(true)}}>Edit</Button>
        </>)}

      </Heading>
      {isEditMode ? (<>
        <FormikFormEditTool/>
      </>) : (<>

        <SimpleGrid columns={1} spacing='20px' >
          <FilesDisplay label='Images' suid={data.suid} fileArray={data.toolImageS3FN ?? []}/>
          <PropDisplay label='SUID' value={data.suid}/>
          <PropDisplay label='Tool ID' value={data.toolId}/>
          <PropDisplay label='In Service Date' value={format(new Date(data.inServiceDate), 'MMM do, yyyy')}/>
          <PropDisplay label='Status' value={data.toolStatus}/>
          <PropDisplay label='Model Number' value={data.modelNumber}/>
          <PropDisplay label='Serial Number' value={data.serialNumber}/>
          <PropDisplay label='Description' value={data.description}/>
          <PropDisplay label='Comments' value={data.comments}/>
          <PropDisplay label='Location' value={data.toolLocation.join(', ')}/>

          <TextXs>Calibration Records</TextXs>
          <Accordion allowToggle>
            {data.clbRecords.map((v,i)=>(<TCRAccItem key={i} idx={i} record={v}/>))}
          </Accordion>
          {showNewTCRForm ? (<>
            <BtnXs onClick={()=>{setShowNewTCRForm(false)}}>Cancel New Calibration Record</BtnXs>
            <FormikFormAddTCR/>
          </>):(<>
            <BtnXs onClick={()=>{setShowNewTCRForm(true)}}>Add New Calibration Record</BtnXs>
          </>)}
        </SimpleGrid>
      </>)}

    </>) : (<HFlexCC align={'middle'} h='100%'><Spinner alignSelf={'center'}/></HFlexCC>)}
  </VFlex>);
}
