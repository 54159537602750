import { Box, chakra, Flex, Heading, useToken, } from '@chakra-ui/react';
import { HFlex, VFlex } from '../../views/bits/UtilityTags.js';
import { format } from 'date-fns';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { colorWheels } from '../../theme/foundations/colorWheels.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
//http://pdfmake.org/playground.html



const valsMock = {
  dateToday: format(Date.now(), 'MM/dd/yy'),
  initiatedBy: 'Susan Woszicski',
  approvedName: 'Greg Hodges',
  approvedDate: format(Date.now(), 'MM/dd/yy'),
  ootList: [
    {
      toolId:'ACH-2031',
      description:'A great tool.  The best tool.  Everyone agrees.',
      lastInTolDate:'01/13/22',
    },
    {
      toolId:'ACH-2030',
      description:'Frankly not as great of a tool as ACH-2031.',
      lastInTolDate:'07/13/21',
    },
  ]
}
const dkBlue = colorWheels.blue['700']
const mdBlue = colorWheels.gray['300']
const ltBlue = colorWheels.gray['100']
const ltGreen = colorWheels.green['100']
const ltPink = colorWheels.red['100']

const ootrPdfDocDef = (values)=> {
  const v = values ?? valsMock;
  return {
    styles: {
      header: { fontSize: 18, bold: true, margin: [0, 0, 0, 10] },
      subheader: { fontSize: 16, bold: true, margin: [0, 10, 0, 5] },
      tableExample: { margin: [0, 5, 0, 15] },
      tablePosRight: { color:'red',width:'100%',justify: 'end', position: 'absolute', right: 0, alignment:'right' },
      tableHeader: { bold: true, fontSize: 13, color: 'black' },
      bgDkBlue: {fillColor:dkBlue, color:'white'},
      bgMdBlue: {fillColor:mdBlue},
      bgLtBlue: {fillColor:ltBlue},
      bgLtBlueRed: {fillColor:ltBlue, color:'red'},
      bgLtGreen: {fillColor:ltGreen},
      bgLtPink: {fillColor:ltPink},
    },
    defaultStyle: { fontSize: 10 },
    pageSize: 'A4',
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [ 20, 20, 20, 20 ],
    info: { title: 'Calibration Out of Tolerance Investigation',
      author: 'CloudCal', creator: 'CloudCal', producer: 'CloudCal', },
    content: [
      {
        text: 'Calibration Out of Tolerance Investigation',
        style: 'header',  alignment:'center'
      },
      {
        style: {
          alignment:'right'
        },
        table: {
          widths: ['auto', 'auto' ],
          body: [
            [
              { text: 'Date:', style: 'bgDkBlue' },
              { text: v.dateToday, style: 'bgLtBlueRed' }
            ],
            [
              { text: 'Initiated By:', style: 'bgDkBlue' },
              { text: v.initiatedBy, style: 'bgLtBlueRed' }
            ]
          ]
        }
      },
      ' ',
      {
        style: 'tableExample',
        layout: {
          fillColor: function (i, node) {
            return i===1?mdBlue:i>1?ltBlue:null;
          }
        },
        table: {
          widths: [110, '*', 80],
          body: [
            [
              {text: `During the established calibration cycle, the following tools were identified with a received as condition "Out of Tolerance":`,
               style: 'bgDkBlue', colSpan: 3},{},{}
            ],
            [
              { text: 'Tool ID', margin: [0,12,0,0], alignment:'center'},
              { text: 'Tool Description', margin: [0,12,0,0], alignment:'center'},
              { text: 'Date of last\n"In-Tolerance"\ncalibration', alignment:'center'},
            ],
            ...v.ootList.map((v,i)=>[v.toolId,v.description,v.lastInTolDate])
          ]
        }
      },
      `Conduct an investigation for each device identified above, from the date of last "in-tolerance" calibration activity, to the most current "out of tolerance" calibration identification.`,
      {
        style: 'tableExample',
        color: '#444',
        table: {
          widths: ['*',31,31,31,31],
          // keepWithHeaderRows: 1,
          heights: ['auto','auto',90,'auto',120,],
          body: [
            [
              {text: `Did the above investigation reveal any potential product impact\nduring the period of use?`, style: 'bgLtBlue'},
              {text: 'Yes', style: 'bgLtPink', margin: [0,6,0,0], alignment: 'center'},
              {text: '', style: 'bgLtBlue'},
              {text: 'No', style: 'bgLtGreen', margin: [0,6,0,0], alignment: 'center'},
              {text: '', style: 'bgLtBlue'}
            ],
            [{text: 'Please list affected product(s) in the section provided below:', style: 'bgDkBlue', colSpan:5,},],
            [{text: '', style: 'bgLtBlue', colSpan:5},],
            [{text: 'Please type out investigation details and results in the section provided below:', style: 'bgDkBlue', colSpan:5,},],
            [{text: '', style: 'bgLtBlue', colSpan:5}],
          ]
        }
      },
      ' ',
      {
        style: {
          alignment:'right'
        },
        table: {
          widths: ['*',100, 100 ],
          body: [
            [
              { text:'',border: [false,false,false,false]},
              { text: 'Report Approved By:', style: 'bgDkBlue' },
              { text: v.approvedName, style: 'bgLtBlueRed' }
            ],
            [
              { text:'',border: [false,false,false,false]},
              { text: 'Approval Date:', style: 'bgDkBlue' },
              { text: v.approvedDate, style: 'bgLtBlueRed' }
            ]
          ]
        }
      },

    ],

  }
}

// const doc = (
//   <document defaultStyle={{ font: 'sans-serif', fontSize: 12 }}>
//     <content>
//       <OOTRPdfTemplate />
//     </content>
//   </document>
// );
//https://github.com/schibsted/jsx-pdf/blob/master/example/index.js
export function makeOOTRPdf(values) {
  pdfMake.createPdf(
    ootrPdfDocDef(values)
  ).open();
}
