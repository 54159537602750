import { ErrorMessage, Field, useField } from 'formik';
import { Box, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import TextError from './TextError.js';
import React, { useEffect } from 'react';
import { MultiSelect } from 'chakra-multiselect';

export function FmMultiSelect (props) {
  const { label, name, options, isRequired=false, isNA=false, ...rest } = props

  const [field, meta, helpers] = useField(name);
  useEffect(()=>{
    helpers.setValue(isNA?'N/A':meta.initialValue)
  },[isNA])
  useEffect(()=>{
    helpers.setValue(meta.initialValue)
  },[])

  return (
    <FormControl isRequired={isRequired} isInvalid={meta.touched && !!meta.error} {...rest}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      {isNA && (<Box color='gray.400'>N/A</Box>)}
      {!isNA && (
      <MultiSelect
        listProps={{ zIndex: '10' }} name={name}
        options={options}
        value={field.value}
        onChange={(e)=>{
          helpers.setValue(e);
        }}
        />
      )}
      <ErrorMessage as={FormErrorMessage} component={TextError} name={name} />
    </FormControl>
  )
}
