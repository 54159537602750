
import React, { useEffect } from 'react';
import { Field, ErrorMessage, useField } from 'formik';
import TextError from './TextError'
import { Box, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';


export default function FmInputText (props) {
  const { label, name, isRequired=false, isNA=false, ...rest } = props
  const [field, meta, helpers] = useField(name);
  useEffect(()=>{
    helpers.setValue(isNA?'N/A':meta.initialValue)
  },[isNA])

  return (
    <FormControl isRequired={isRequired} isInvalid={meta.touched && !!meta.error} {...rest}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      {isNA && (<Box color='gray.400'>N/A</Box>)}
      {!isNA && (
        <Field as={Input} type='text' id={name} name={name} {...field} />
      )}

      <ErrorMessage as={FormErrorMessage} component={TextError} name={name} />
    </FormControl>
  )
}
